<template>
  <div>
    <div id="component-chat-log" class="m-8">
      <div class="date sticky date-active"></div>
      <div
        v-for="(msg, index) in msgsArr"
        class="msg-grp-container mb-6"
        :key="index"
      >
        <div class="date" v-if="msg.grouped_message_date">
          {{ groupDate(msg.grouped_message_date) }}
        </div>
        <div
          class="flex items-start"
          :class="{ 'flex-row-reverse': bindClass(msg) }"
          style="position: relative"
        >
          <template>
            <template v-if="bindClass(msg)">
              <img
                :src="iconLogo"
                style="border-radius: 50%"
                class="sm:ml-5 ml-3"
                alt="User"
                width="39.75"
              />
              <span class="sender-name">{{ msg.sender_name || msg.name }}</span>
            </template>
            <template v-else>
              <img
                src="@/assets/images/elements/user2.svg"
                class="sm:mr-5 mr-3"
                alt="User"
                width="39.75"
              />
            </template>
          </template>
          <div
            class="msg break-words relative shadow-md rounded py-3 px-4 mb-2 rounded-lg max-w-sm"
            :style="
              checkAudio(msg.image)
                ? 'backgroundColor: #31006F !important; display: flex; align-items: center; width: 265px; max-width: 265px !important'
                : ''
            "
            :class="[
              bindClass(msg)
                ? 'is-employee border border-solid border-transparent bg-white'
                : 'is-client bg-primary text-white',
              (!checkImageFormat(msg.image) && msg.image) ||
              (!checkImageFormat(msg.file) && msg.file)
                ? 'file-msg'
                : '',
            ]"
          >
            <span v-if="typeOfChat == 1">
              <div v-if="checkAudio(msg.image)">
                <div class="audio-player">
                  <audio
                    @timeupdate="
                      changeTimelinePosition(
                        'audio' + index,
                        'timeline' + index
                      )
                    "
                    @ended="audioEnded('playerButton' + index)"
                    :ref="'audio' + index"
                    :src="msg.image"
                  ></audio>
                  <div class="controls">
                    <button
                      @click="
                        toggleAudio('audio' + index, 'playerButton' + index)
                      "
                      class="player-button"
                      :ref="'playerButton' + index"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="#fff"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </button>
                    <input
                      :ref="'timeline' + index"
                      @change="changeSeek('audio' + index, 'timeline' + index)"
                      type="range"
                      class="timeline"
                      max="100"
                      value="0"
                    />
                  </div>
                </div>
              </div>
              <a
                :href="msg.image"
                target="_blank"
                v-else-if="checkImageFormat(msg.image)"
                class="img-file"
              >
                <img
                  class="image-file"
                  :src="msg.image"
                  alt=""
                  loading="lazy"
                />
              </a>
              <a
                :href="msg.image"
                target="_blank"
                v-else-if="msg.image"
                :download="msg.file_name"
              >
                <div style="text-align: right; display: flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="44"
                    viewBox="0 0 40 44"
                  >
                    <g
                      id="Group_2163"
                      data-name="Group 2163"
                      transform="translate(-243 -534)"
                    >
                      <rect
                        id="Rectangle_1384"
                        data-name="Rectangle 1384"
                        width="40"
                        height="44"
                        rx="6"
                        transform="translate(243 534)"
                        fill="rgba(49,0,111,0.1)"
                      />
                      <g
                        id="Icon_feather-file"
                        data-name="Icon feather-file"
                        transform="translate(249 542)"
                      >
                        <path
                          id="Path_3050"
                          data-name="Path 3050"
                          d="M15.541,3H8.12A2.12,2.12,0,0,0,6,5.12V22.083A2.12,2.12,0,0,0,8.12,24.2H20.842a2.12,2.12,0,0,0,2.12-2.12V10.421Z"
                          transform="translate(0 0)"
                          fill="none"
                          stroke="#31006f"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                        />
                        <path
                          id="Path_3051"
                          data-name="Path 3051"
                          d="M19.5,3v7.421h7.421"
                          transform="translate(-3.959 0)"
                          fill="none"
                          stroke="#31006f"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                        />
                      </g>
                    </g>
                  </svg>
                  <div>
                    <p>{{ msg.file_name }}</p>
                    <span>{{ msg.file_size }}</span>
                  </div>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18.202"
                  height="18.202"
                  viewBox="0 0 18.202 18.202"
                >
                  <g
                    id="Icon_feather-download"
                    data-name="Icon feather-download"
                    transform="translate(-3.5 -3.5)"
                  >
                    <path
                      id="Path_3047"
                      data-name="Path 3047"
                      d="M20.7,22.5v3.6a1.8,1.8,0,0,1-1.8,1.8H6.3a1.8,1.8,0,0,1-1.8-1.8V22.5"
                      transform="translate(0 -7.199)"
                      fill="none"
                      stroke="#31006f"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                    <path
                      id="Path_3048"
                      data-name="Path 3048"
                      d="M10.5,15,15,19.5,19.5,15"
                      transform="translate(-2.4 -4.199)"
                      fill="none"
                      stroke="#31006f"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                    <path
                      id="Path_3049"
                      data-name="Path 3049"
                      d="M18,15.3V4.5"
                      transform="translate(-5.399)"
                      fill="none"
                      stroke="#31006f"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                  </g>
                </svg>
              </a>
              <a
                v-else-if="msg.latitude"
                target="_blank"
                class="img-file"
                :href="
                  'https://www.google.com/maps/dir/?api=1&destination=' +
                  msg.latitude +
                  ',' +
                  msg.longitude
                "
              >
                <img
                  src="@/assets/images/elements/map.jpg"
                  alt="map"
                  style="width: 100%"
                />
              </a>
              <span>
                <span v-html="checkLink(msg)"></span>
                <span v-if="msg.is_updated == 1"
                  ><u
                    class="font-bold ml-2 cursor-pointer"
                    style="font-size: 12px"
                    @click="
                      (openLogEdit = true), (dataLogEdit = msg.reply_logs)
                    "
                    >معدلة</u
                  ></span
                >
              </span>
            </span>
            <span v-else>
              <a
                :href="msg.file"
                target="_blank"
                v-if="checkImageFormat(msg.file)"
                class="img-file"
              >
                <img class="image-file" :src="msg.file" alt="" loading="lazy" />
              </a>
              <a
                :href="msg.file"
                target="_blank"
                v-else-if="msg.file"
                :download="msg.message"
              >
                <div style="text-align: right; display: flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="44"
                    viewBox="0 0 40 44"
                  >
                    <g
                      id="Group_2163"
                      data-name="Group 2163"
                      transform="translate(-243 -534)"
                    >
                      <rect
                        id="Rectangle_1384"
                        data-name="Rectangle 1384"
                        width="40"
                        height="44"
                        rx="6"
                        transform="translate(243 534)"
                        fill="rgba(49,0,111,0.1)"
                      />
                      <g
                        id="Icon_feather-file"
                        data-name="Icon feather-file"
                        transform="translate(249 542)"
                      >
                        <path
                          id="Path_3050"
                          data-name="Path 3050"
                          d="M15.541,3H8.12A2.12,2.12,0,0,0,6,5.12V22.083A2.12,2.12,0,0,0,8.12,24.2H20.842a2.12,2.12,0,0,0,2.12-2.12V10.421Z"
                          transform="translate(0 0)"
                          fill="none"
                          stroke="#31006f"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                        />
                        <path
                          id="Path_3051"
                          data-name="Path 3051"
                          d="M19.5,3v7.421h7.421"
                          transform="translate(-3.959 0)"
                          fill="none"
                          stroke="#31006f"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                        />
                      </g>
                    </g>
                  </svg>
                  <div>
                    <p>{{ msg.file_name }}</p>
                    <span>{{ msg.file_size }}</span>
                  </div>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18.202"
                  height="18.202"
                  viewBox="0 0 18.202 18.202"
                >
                  <g
                    id="Icon_feather-download"
                    data-name="Icon feather-download"
                    transform="translate(-3.5 -3.5)"
                  >
                    <path
                      id="Path_3047"
                      data-name="Path 3047"
                      d="M20.7,22.5v3.6a1.8,1.8,0,0,1-1.8,1.8H6.3a1.8,1.8,0,0,1-1.8-1.8V22.5"
                      transform="translate(0 -7.199)"
                      fill="none"
                      stroke="#31006f"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                    <path
                      id="Path_3048"
                      data-name="Path 3048"
                      d="M10.5,15,15,19.5,19.5,15"
                      transform="translate(-2.4 -4.199)"
                      fill="none"
                      stroke="#31006f"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                    <path
                      id="Path_3049"
                      data-name="Path 3049"
                      d="M18,15.3V4.5"
                      transform="translate(-5.399)"
                      fill="none"
                      stroke="#31006f"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                  </g>
                </svg>
              </a>
              <a
                v-else-if="msg.latitude"
                target="_blank"
                class="img-file"
                :href="
                  'https://www.google.com/maps/dir/?api=1&destination=' +
                  msg.latitude +
                  ',' +
                  msg.longitude
                "
              >
                <img
                  src="@/assets/images/elements/map.jpg"
                  alt="map"
                  style="width: 100%"
                />
              </a>
              <span v-html="checkLink(msg)"></span>
            </span>
            <p
              style="font-size: 11px; text-align: left"
              :style="checkAudio(msg.image) ? 'color: #fff' : ''"
              class="msg-date"
            >
              {{
                msg.updated_at
                  ? formatDate(msg.updated_at)
                  : formatDate(msg.created_at)
              }}
            </p>
            <svg
              v-if="!bindClass(msg) && msg.is_seen == 1 && typeOfChat == 1"
              class="seen"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="9.327"
              viewBox="0 0 18 9.327"
            >
              <g
                id="Group_2287"
                data-name="Group 2287"
                transform="translate(-307 -455.811)"
              >
                <path
                  id="Icon_ionic-ios-checkmark"
                  data-name="Icon ionic-ios-checkmark"
                  d="M22.371,13.282l-.981-1.009a.211.211,0,0,0-.156-.067h0a.2.2,0,0,0-.156.067l-6.8,6.849-2.474-2.474a.215.215,0,0,0-.312,0l-.992.992a.222.222,0,0,0,0,.318l3.121,3.121a.987.987,0,0,0,.652.318,1.034,1.034,0,0,0,.646-.306h.006l7.451-7.49A.238.238,0,0,0,22.371,13.282Z"
                  transform="translate(302.565 443.604)"
                  fill="#31006f"
                />
                <path
                  id="Icon_ionic-ios-checkmark-2"
                  data-name="Icon ionic-ios-checkmark"
                  d="M22.371,13.282l-.981-1.009a.211.211,0,0,0-.156-.067h0a.2.2,0,0,0-.156.067l-6.8,6.849-2.474-2.474a.215.215,0,0,0-.312,0l-.992.992a.222.222,0,0,0,0,.318l3.121,3.121a.987.987,0,0,0,.652.318,1.034,1.034,0,0,0,.646-.306h.006l7.451-7.49A.238.238,0,0,0,22.371,13.282Z"
                  transform="translate(296.565 443.742)"
                  fill="#31006f"
                />
              </g>
            </svg>
            <svg
              v-else-if="!bindClass(msg) && typeOfChat == 1"
              class="seen"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="9.327"
              viewBox="0 0 18 9.327"
            >
              <g
                id="Group_2287"
                data-name="Group 2287"
                transform="translate(-307 -455.811)"
              >
                <path
                  id="Icon_ionic-ios-checkmark"
                  data-name="Icon ionic-ios-checkmark"
                  d="M22.371,13.282l-.981-1.009a.211.211,0,0,0-.156-.067h0a.2.2,0,0,0-.156.067l-6.8,6.849-2.474-2.474a.215.215,0,0,0-.312,0l-.992.992a.222.222,0,0,0,0,.318l3.121,3.121a.987.987,0,0,0,.652.318,1.034,1.034,0,0,0,.646-.306h.006l7.451-7.49A.238.238,0,0,0,22.371,13.282Z"
                  transform="translate(302.565 443.604)"
                  fill="#633998"
                  opacity="0.501"
                />
                <path
                  id="Icon_ionic-ios-checkmark-2"
                  data-name="Icon ionic-ios-checkmark"
                  d="M22.371,13.282l-.981-1.009a.211.211,0,0,0-.156-.067h0a.2.2,0,0,0-.156.067l-6.8,6.849-2.474-2.474a.215.215,0,0,0-.312,0l-.992.992a.222.222,0,0,0,0,.318l3.121,3.121a.987.987,0,0,0,.652.318,1.034,1.034,0,0,0,.646-.306h.006l7.451-7.49A.238.238,0,0,0,22.371,13.282Z"
                  transform="translate(296.565 443.742)"
                  fill="#633998"
                  opacity="0.501"
                />
              </g>
            </svg>
          </div>
        </div>
        <div
          class="return-on-sender"
          v-if="typeOfChat == 2 && hasReturnOnSender(msg)"
        >
          <p
            class="return-on-sender-counter"
            :class="
              !msg.actions.return_on_sender.enabled
                ? 'disabled-return-on-sender-counter'
                : ''
            "
          >
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 5.00098V11.001L15 13.001M21 11.001C21 16.5238 16.5228 21.001 11 21.001C5.47715 21.001 1 16.5238 1 11.001C1 5.47813 5.47715 1.00098 11 1.00098C16.5228 1.00098 21 5.47813 21 11.001Z"
                stroke="#31006F"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span ref="countdown">00:00</span>
            {{
              msg.actions.return_on_sender.enabled
                ? startTimer(
                    60 *
                      diff_minutes(
                        new Date(msg.actions.return_on_sender.end_date),
                        new Date()
                      ),
                    msg
                  )
                : ""
            }}
          </p>
          <button
            v-if="!loadingReturnOnSender"
            class="return-on-sender-btn"
            :class="
              !msg.actions.return_on_sender.enabled
                ? 'disabled-return-on-sender-btn'
                : ''
            "
            :disabled="!msg.actions.return_on_sender.enabled"
            @click="executionReturnOnSender(msg.actions.return_on_sender)"
          >
            {{ msg.actions.return_on_sender.hint }}
          </button>
          <button v-else class="return-on-sender-btn">
            <SpinnerColor />
          </button>
        </div>
      </div>
      <rate
        v-if="
          typeOfChat == 1 &&
          (single_ticket.status == 2 || this.ticketStatus == 2)
        "
      />
    </div>
    <div v-if="openLogEdit">
      <div class="overlay" @click="openLogEdit = false"></div>
      <div class="edit-log py-6">
        <div class="flex justify-center items-center mb-4">
          <h4 class="flex items-center font-bold">سجل تعديلات الرسالة</h4>
        </div>
        <vs-divider class="m-0" />
        <div>
          <table>
            <thead>
              <tr>
                <th>الرسالة</th>
                <th>تاريخ التعديل</th>
                <th>بواسطة</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(log, index) in dataLogEdit" :key="index">
                <td>{{ log.description.ar }}</td>
                <td>
                  {{
                    new Date(log.created_at).toLocaleDateString("ar-EG", {
                      hour: "2-digit",
                      minute: "2-digit",
                      timeZone: "UTC",
                    })
                  }}
                </td>
                <td>{{ log.user_name }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import SpinnerColor from "@/views/components/table-components/SpinnerColor2.vue";
import rate from "./Rate";
import notify from "@/mixins/helper.js";
import notifyState from "@/mixins/notifyState";
import imageFormats from "@/mixins/imageFormats.js";
import dateFormat from "@/mixins/date.js";

export default {
  mixins: [notify, imageFormats, dateFormat, notifyState],
  props: {
    userId: {
      required: true,
    },
    load: {
      type: Boolean,
    },
    myMsg: {
      type: String,
    },
    typeOfChat: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      msgsArr: [],
      currentDate: "",
      isClient: "",
      playerButton: this.$refs.playerButton,
      audio: this.$refs.audio,
      playIcon: `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#fff">
            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clip-rule="evenodd" />
        </svg>
      `,
      pauseIcon: `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#fff">
            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
        </svg>
      `,
      soundIcon: `
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#fff">
        <path fill-rule="evenodd" d="M9.383 3.076A1 1 0 0110 4v12a1 1 0 01-1.707.707L4.586 13H2a1 1 0 01-1-1V8a1 1 0 011-1h2.586l3.707-3.707a1 1 0 011.09-.217zM14.657 2.929a1 1 0 011.414 0A9.972 9.972 0 0119 10a9.972 9.972 0 01-2.929 7.071 1 1 0 01-1.414-1.414A7.971 7.971 0 0017 10c0-2.21-.894-4.208-2.343-5.657a1 1 0 010-1.414zm-2.829 2.828a1 1 0 011.415 0A5.983 5.983 0 0115 10a5.984 5.984 0 01-1.757 4.243 1 1 0 01-1.415-1.415A3.984 3.984 0 0013 10a3.983 3.983 0 00-1.172-2.828 1 1 0 010-1.415z" clip-rule="evenodd" />
    </svg>`,
      muteIcon: `
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#fff">
        <path fill-rule="evenodd" d="M9.383 3.076A1 1 0 0110 4v12a1 1 0 01-1.707.707L4.586 13H2a1 1 0 01-1-1V8a1 1 0 011-1h2.586l3.707-3.707a1 1 0 011.09-.217zM12.293 7.293a1 1 0 011.414 0L15 8.586l1.293-1.293a1 1 0 111.414 1.414L16.414 10l1.293 1.293a1 1 0 01-1.414 1.414L15 11.414l-1.293 1.293a1 1 0 01-1.414-1.414L13.586 10l-1.293-1.293a1 1 0 010-1.414z" clip-rule="evenodd" />
    </svg>`,
      openLogEdit: false,
      dataLogEdit: [],
      ticketStatus: undefined,
      clientId: JSON.parse(localStorage.getItem("userData")).userId,
      regx: /(?:https?|ftp):\/\/[\n\S]+/g,
      iconLogo: process.env.VUE_APP_CONFIGS_CHAT_ICON,
      loadingReturnOnSender: false,
      intervalTimer: undefined,
      startReturnTimer: true,
      returnOnSenderObj: {},
    };
  },
  components: {
    SpinnerColor,
    rate,
  },
  computed: {
    ...mapGetters("chat", ["chat", "chat_mission"]),
    ...mapGetters("ticket", ["replies_ticket", "single_ticket"]),
    ...mapState("dataList", ["successApi"]),
    messagesChat() {
      if (this.typeOfChat == 1) {
        this.msgsArr = this.replies_ticket.slice();
      } else if (this.typeOfChat == 2) {
        this.msgsArr = JSON.parse(JSON.stringify(this.chat));
      } else if (this.typeOfChat == 3) {
        this.msgsArr = this.chat_mission.slice();
      }
    },
    stopReturnOnSender() {
      this.returnOnSenderObj.actions.return_on_sender.enabled = false;
      clearInterval(this.intervalTimer);
      this.$refs["countdown"][0].textContent = "00:00";
    },
  },
  watch: {
    userId: {
      immediate: true,
      deep: true,
      handler() {
        this.msgsArr = [];
      },
    },
  },
  methods: {
    ...mapActions("ticket", ["replaySeen"]),
    ...mapActions("dataList", ["returnOnSender"]),
    scrollToBottom() {
      this.$nextTick(() => {
        this.$parent.$el.scrollTop = this.$parent.$el.scrollHeight;
        var messagesBox = this.$parent.$refs.messagesBox;
        messagesBox.scrollTop = messagesBox.scrollHeight;
      });
    },
    bindClass(msg) {
      if (msg.your_message == 0 || msg.is_client_message == 0) {
        return true;
      }
    },
    groupDate(msgDate) {
      const today = new Date();
      const otherDate = new Date(msgDate);
      if (
        otherDate.getDate() == today.getDate() &&
        otherDate.getMonth() == today.getMonth() &&
        otherDate.getFullYear() == today.getFullYear()
      ) {
        return "اليوم";
      } else if (
        otherDate.getDate() == today.getDate() - 1 &&
        otherDate.getMonth() == today.getMonth() &&
        otherDate.getFullYear() == today.getFullYear()
      ) {
        return "أمس";
      } else {
        return new Date(msgDate).toLocaleDateString("ar-EG", {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        });
      }
    },
    toggleAudio(audio, player) {
      const audioVoice = this.$refs[audio][0];
      const playerVoice = this.$refs[player][0];
      if (audioVoice.paused) {
        audioVoice.play();
        playerVoice.innerHTML = this.pauseIcon;
      } else {
        audioVoice.pause();
        playerVoice.innerHTML = this.playIcon;
      }
    },
    audioEnded(player) {
      const playerVoice = this.$refs[player][0];
      playerVoice.innerHTML = this.playIcon;
    },
    changeSeek(audio, timeline) {
      const audioVoice = this.$refs[audio][0];
      const timelineVoice = this.$refs[timeline][0];
      const time = (timelineVoice.value * audioVoice.duration) / 100;
      audioVoice.currentTime = time;
    },
    changeTimelinePosition(audio, timeline) {
      const audioVoice = this.$refs[audio][0];
      const timelineVoice = this.$refs[timeline][0];
      const percentagePosition =
        (100 * audioVoice.currentTime) / audioVoice.duration;
      timelineVoice.style.backgroundSize = `${percentagePosition}% 100%`;
      timelineVoice.value = percentagePosition;
    },
    checkLink(msg) {
      let regx = /(?:https?|ftp):\/\/[\n\S]+/g;
      if (regx.test(msg.message)) {
        const str = msg.message.match(regx)[0];
        let link;
        let color;
        if (!this.bindClass(msg)) {
          color = "#FFF";
        } else {
          color = "";
        }
        link = msg.message.replace(
          regx,
          `<u><a target="_blank" style="color: ${color}" href="${str}">${str}</a></u>`
        );
        return link;
      } else {
        return msg.message;
      }
    },
    hasReturnOnSender(msg) {
      if (msg.actions) {
        if (msg.actions.return_on_sender) return true;
        else return false;
      } else return false;
    },
    startTimer(duration, message) {
      if (message.actions.return_on_sender.enabled && this.startReturnTimer) {
        this.returnOnSenderObj = message;
        this.startReturnTimer = false;
        let timer = duration,
          minutes,
          seconds;
        this.intervalTimer = setInterval(() => {
          minutes = parseInt(timer / 60);
          seconds = parseInt(timer % 60);

          minutes = minutes < 10 ? "0" + minutes : minutes;
          seconds = seconds < 10 ? "0" + seconds : seconds;

          this.$refs["countdown"][0].textContent = minutes + ":" + seconds;
          if (--timer < 0) {
            this.stopReturnOnSender;
          }
        }, 1000);
      }
    },
    diff_minutes(dt2, dt1) {
      var diff = (dt2.getTime() - dt1.getTime()) / 1000;
      diff /= 60;
      return diff;
    },
    async executionReturnOnSender(returnObj) {
      this.loadingReturnOnSender = true;
      await this.returnOnSender({
        order_id: returnObj.order_id,
      });
      if (this.successApi) {
        this.stopReturnOnSender;
        this.notifySuccess("success");
      } else {
        this.notifyFaild("faild");
      }
      this.loadingReturnOnSender = false;
    },
  },
  updated() {
    this.scrollToBottom();
  },
  mounted() {
    this.messagesChat;
    this.scrollToBottom();
    this.$socket.emit("join", {
      name: `ticket-reply-${this.single_ticket.id}-created`,
    });
    this.$socket.emit("join", {
      name: `mission-message-${this.userId}-created`,
    });
    this.$socket.emit("join", { name: `order-message-${this.userId}-created` });
    this.$socket.emit("join", {
      name: `ticket-status-${this.single_ticket.id}-changed`,
    });
    this.$socket.emit("join", {
      name: `reply-${this.single_ticket.id}-update`,
    });
    this.$socket.emit("join", { name: `reply-${this.single_ticket.id}-seen` });
    this.$socket.on(`ticket-reply-${this.single_ticket.id}-created`, (data) => {
      if (data.body.is_comment == 0) {
        this.msgsArr.push(data.body);
        if (data.body.is_client_message == 0) {
          this.replaySeen(parseInt(data.body.id));
        }
        this.isClient = data.body.is_client_message;
      }
    });
    this.$socket.on(`mission-message-${this.userId}-created`, (data) => {
      let obj = data.body;
      obj.message = obj.content;
      if (this.clientId == obj.user_id) {
        obj.your_message = 1;
      }
      this.msgsArr.push(obj);
      this.isClient = data.body.isClientMessage;
    });
    this.$socket.on(`order-message-${this.userId}-created`, (data) => {
      let obj = data.body;
      obj.message = obj.content;
      if (this.clientId == obj.user_id) {
        obj.your_message = 1;
      }
      if (obj.action_type == "return_on_sender" && obj.your_message == 1) {
        this.stopReturnOnSender;
      }
      this.msgsArr.push(obj);
      this.isClient = data.body.isClientMessage;
    });
    this.$socket.on(
      `ticket-status-${this.single_ticket.id}-changed`,
      (data) => {
        this.ticketStatus = data.body.status;
        this.$emit("changeOfTicketStatus", data.body.status);
      }
    );
    this.$socket.on(`reply-${this.single_ticket.id}-update`, (data) => {
      let obj = data.body;
      obj.updated_at = obj.created_at;
      obj.reply_logs.forEach((element) => {
        element.description = JSON.parse(element.description);
      });
      let index = this.msgsArr.findIndex((n) => n.id == obj.id);
      this.$set(this.msgsArr, index, obj);
    });
    this.$socket.on(`reply-${this.single_ticket.id}-seen`, (data) => {
      let obj = data.body;
      let index = this.msgsArr.findIndex((n) => n.id == obj.id);
      this.$set(this.msgsArr, index, obj);
    });
    (this.playerButton = this.$refs.playerButton),
      (this.audio = this.$refs.audio);
  },
  unmounted() {
    this.$socket.emit("leave", {
      name: `ticket-reply-${this.single_ticket.id}-created`,
    });
    this.$socket.emit("leave", {
      name: `mission-message-${this.userId}-created`,
    });
    this.$socket.emit("leave", {
      name: `order-message-${this.userId}-created`,
    });
    this.$socket.emit("leave", {
      name: `ticket-status-${this.single_ticket.id}-changed`,
    });
    this.$socket.emit("leave", {
      name: `reply-${this.single_ticket.id}-update`,
    });
    this.$socket.emit("leave", { name: `reply-${this.single_ticket.id}-seen` });
    this.$socket.off(`ticket-reply-${this.single_ticket.id}-created`);
    this.$socket.off(`mission-message-${this.userId}-created`);
    this.$socket.off(`order-message-${this.userId}-created`);
    this.$socket.off(`ticket-status-${this.single_ticket.id}-changed`);
    this.$socket.off(`reply-${this.single_ticket.id}-update`);
    this.$socket.off(`reply-${this.single_ticket.id}-seen`);
  },
  beforeDestroy() {
    if (this.intervalTimer) {
      clearInterval(this.intervalTimer);
    }
  },
};
</script>

<style lang="scss">
.chat__bg {
  background-image: url("./Background.png");
}
#component-chat-log {
  position: relative;
}
.vs-button {
  padding: 0.35rem 1.6rem !important;
  font-family: "Cairo";
}
</style>

<style lang="scss" scoped>
@import "@/assets/scss/vuexy/_variables.scss";
table {
  border-collapse: collapse;
  background-color: #fff;
  border-radius: 6px;
  width: 100%;
  thead {
    tr {
      background-color: #f3f2f7;
      th {
        text-align: left;
        border-bottom: 0.0625rem solid rgba(0, 0, 0, 0.1);
        padding: 0.75rem 2.5rem;
      }
    }
  }
  tbody {
    tr {
      border-bottom: solid 1px #e9e9eb;
      margin-bottom: 8px;
      td {
        text-align: left;
        padding: 0.75rem 2.5rem;
        font-size: 0.875rem;
      }
    }
  }
}
.overlay {
  background: #00000061;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9999999;
}
.edit-log {
  min-width: 600px;
  background-color: #fff;
  border-radius: 6px;
  position: fixed;
  z-index: 99999999;
  top: 50%;
  right: 35%;
  transform: translateY(-50%);
}
.date {
  text-align: center;
  background-color: #fff;
  width: fit-content;
  margin: auto;
  color: #6e6b7b;
  padding: 2px 12px;
  border-radius: 4px;
  box-shadow: 0px 3px 6px #00000014;
  position: sticky;
  z-index: 9;
}
.date-active {
  display: block;
}
.sticky {
  position: sticky;
  top: 53px;
}
.image-file {
  width: 100%;
  display: block;
}
.is-client {
  a {
    display: flex;
    align-items: center;
    color: #fff;
    svg {
      margin-right: 5px;
      path {
        fill: #fff;
      }
    }
  }
}
.is-employee {
  a {
    display: flex;
    align-items: center;
    svg {
      margin-right: 5px;
    }
  }
}

.img-file {
  width: 250px;
  max-height: 335px;
  overflow: hidden;
  margin-left: -12px;
  margin-right: -12px;
  margin-top: -8px;
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
}
.file-msg {
  background-color: #31006f0d !important;
  padding: 6px 8px !important;
  color: $mainColor1 !important;
  a {
    color: $mainColor1;
    font-size: 10px;
    line-height: 21px;
    display: flex;
    justify-content: space-between;
    min-width: 194px;
    align-items: center;
    margin-bottom: 8px;
  }
  .msg-date {
    color: $mainColor1;
  }
}
.sender-name {
  position: absolute;
  top: -18px;
  font-size: 10px;
  font-weight: bold;
  color: #31006f;
  right: 60px;
}
.return-on-sender {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  .return-on-sender-btn {
    border-radius: 4px;
    background-color: $mainColor1;
    color: #fff;
    padding: 10px;
    width: 562px;
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    .loader {
      border: 2px solid #fff;
      border-top: 2px solid rgba(49, 0, 111, 0.1019607843);
    }
  }
  .return-on-sender-counter {
    display: flex;
    align-items: center;
    gap: 10px;
    color: $mainColor1;
    font-size: 14px;
    font-weight: 600;
    line-height: 27px;
  }
  .disabled-return-on-sender-counter {
    color: #e80505;
    svg path {
      stroke: #e80505;
    }
  }
  .disabled-return-on-sender-btn {
    cursor: not-allowed;
    background-color: #9e9e9e;
    color: #ffffff;
  }
}
</style>
