<template>
  <div class="rate-and-rate-ticket msg-grp-container" v-if="ticketRate == 0">
    <p class="rate-ticket">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12.016"
        height="12.016"
        viewBox="0 0 12.016 12.016"
      >
        <path
          id="Icon_awesome-check-circle"
          data-name="Icon awesome-check-circle"
          d="M12.578,6.57A6.008,6.008,0,1,1,6.57.563,6.008,6.008,0,0,1,12.578,6.57Zm-6.7,3.181,4.457-4.457a.388.388,0,0,0,0-.548L9.785,4.2a.388.388,0,0,0-.548,0L5.6,7.833l-1.7-1.7a.388.388,0,0,0-.548,0l-.548.548a.388.388,0,0,0,0,.548L5.327,9.751a.388.388,0,0,0,.548,0Z"
          transform="translate(-0.563 -0.563)"
          fill="#28C76F"
        />
      </svg>
      {{ rateTicketText }}
    </p>
    <div class="rate flex items-center">
      <h6>{{ titleRateTicket }}</h6>
      <div
        @click="activeRate(rate)"
        class="single-ticket"
        :class="rate.classNameParent"
        v-for="(rate, index) in rates"
        :key="index"
      >
        <img :src="rate.imgSrc" />
        {{ rate.name }}
      </div>
    </div>
    <div class="rate-btn" v-if="rateVlaue">
      <button class="confirm-rate" ref="confirmRate" @click="confirmRate()">
        تأكيد
      </button>
      <button class="cancel-rate" @click="cancelRate()">إلغاء</button>
    </div>
  </div>
  <div class="rate-and-rate-ticket msg-grp-container" v-else>
    <p class="rate-ticket">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12.016"
        height="12.016"
        viewBox="0 0 12.016 12.016"
      >
        <path
          id="Icon_awesome-check-circle"
          data-name="Icon awesome-check-circle"
          d="M12.578,6.57A6.008,6.008,0,1,1,6.57.563,6.008,6.008,0,0,1,12.578,6.57Zm-6.7,3.181,4.457-4.457a.388.388,0,0,0,0-.548L9.785,4.2a.388.388,0,0,0-.548,0L5.6,7.833l-1.7-1.7a.388.388,0,0,0-.548,0l-.548.548a.388.388,0,0,0,0,.548L5.327,9.751a.388.388,0,0,0,.548,0Z"
          transform="translate(-0.563 -0.563)"
          fill="#28C76F"
        />
      </svg>
      تم التقييم بنجاح
    </p>
    <div class="rate flex items-center">
      <h6>{{ titleRateTicket }}</h6>
      <div
        class="single-ticket"
        :class="
          rate.value == ticketRate ? `active-rate-parent${rate.value}` : ''
        "
        v-for="(rate, index) in rates"
        :key="index"
      >
        <img
          :src="rate.imgSrc"
          :class="rate.value == ticketRate ? rates[index].className : ''"
        />
        {{ rate.name }}
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      rates: [
        {
          value: 1,
          imgSrc: require("@/assets/images/elements/rate5.svg"),
          classNameParent: "rate5",
          className: "active-rate5",
          name: "غير راضي جدا",
        },
        {
          value: 2,
          imgSrc: require("@/assets/images/elements/rate4.svg"),
          classNameParent: "rate4",
          className: "active-rate4",
          name: "غير راضي",
        },
        {
          value: 3,
          imgSrc: require("@/assets/images/elements/rate3.svg"),
          classNameParent: "rate3",
          className: "active-rate3",
          name: "عادي",
        },
        {
          value: 4,
          imgSrc: require("@/assets/images/elements/rate2.svg"),
          classNameParent: "rate2",
          className: "active-rate2",
          name: "راضي",
        },
        {
          value: 5,
          imgSrc: require("@/assets/images/elements/rate1.svg"),
          classNameParent: "rate1",
          className: "active-rate1",
          name: "راضي جدا",
        },
      ].reverse(),
      titleRateTicket: "ما مدي رضائك عن التجربة :",
      rateTicketText:
        "تم حل المشكلة بنجاح وإغلاق التذكرة من قبل الموظف المسئول ، قم بتقييم الموظف المسئول عن التذكرة",
      count: 1,
      activeCalss: "",
      rateVlaue: "",
      ticketRate: 0,
    };
  },
  computed: {
    ...mapGetters("ticket", ["single_ticket", "success"]),
  },
  methods: {
    ...mapActions("ticket", ["rateTicket"]),
    activeRate(rate) {
      if (this.count == 1) {
        $(`.single-ticket img`).removeAttr("class");
        $(`.${rate.classNameParent} img`).addClass(rate.className);
        this.activeCalss = $(`.${rate.classNameParent} img`).addClass(
          rate.className
        )[0];
        this.rateVlaue = rate.value;
      }
    },
    async confirmRate() {
      if (this.count == 1) {
        let obj = {
          id: this.single_ticket.id,
          rate: this.rateVlaue,
        };
        await this.rateTicket(obj);
        if (this.success == 1) {
          this.rateVlaue = "";
          this.rateTicketText = "تم التقييم بنجاح";
          this.count++;
        }
      }
    },
    cancelRate() {
      if (this.count == 1) {
        this.rateVlaue = "";
        $(this.activeCalss).removeAttr("class");
      }
    },
  },
  mounted() {
    this.ticketRate = this.single_ticket.rate;
    this.$socket.emit("join", {
      name: `ticket-status-${this.single_ticket.id}-changed`,
    });
    this.$socket.on(
      `ticket-status-${this.single_ticket.id}-changed`,
      (data) => {
        this.ticketRate = data.body.rate;
      }
    );
  },
  unmounted() {
    this.$socket.emit("leave", {
      name: `ticket-status-${this.single_ticket.id}-changed`,
    });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/vuexy/_rate.scss";
</style>
