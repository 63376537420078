<template>
  <div
    data-tour-inner-step="2"
    id="chat-app"
    class="border border-solid d-theme-border-grey-light rounded relative overflow-hidden z-0"
  >
    <v-tour :options="tourOptions" name="chatTour" :steps="steps"></v-tour>
    <vs-sidebar
      class="items-no-padding pm-4"
      parent="#chat-app"
      :click-not-close="clickNotClose"
      :hidden-background="clickNotClose"
      v-model="isChatSidebarActive"
      id="chat-list-sidebar"
    >
      <div class="flex p-4 items-center">
        <div class="relative inline-flex">
          <img
            src="@/assets/images/elements/user2.svg"
            class="mr-4"
            alt="User"
            width="39.75"
          />
          <div
            class="h-3 w-3 border-white border border-solid rounded-full absolute right-12 bottom-0 bg-success"
          ></div>
        </div>
        <div class="chat-tabs justify-start gap-2">
          <div
            :data-chat-tour-step="chat.value == 2 ? 1 : chat.value == 3 ? 2 : 3"
            class="chat-tab w-full"
            @click="tabChat(chat)"
            :class="{ 'active-chat-tab': typeOfChat == chat.value }"
            v-for="(chat, index) in chats"
            :key="index"
          >
          <span>{{ chat.name }}</span>
          <span
              class="notfication-chat"
              v-if="chat.value == 1 && unreadMessagesTicket > 0"
              >{{ unreadMessagesTicket }}</span
            >
            <span
              class="notfication-chat"
              v-else-if="chat.value == 2 && unreadMessagesOrder > 0"
              >{{ unreadMessagesOrder }}</span
            >
            <span
              class="notfication-chat"
              v-else-if="chat.value == 3 && unreadMessagesMisssion > 0"
              >{{ unreadMessagesMisssion }}</span
            >
          </div>
        </div>
        <feather-icon
          class="md:inline-flex lg:hidden -ml-3 cursor-pointer"
          icon="XIcon"
          @click="toggleChatSidebar(false)"
        />
      </div>
      <div class="px-4 mb-4 w-full input-chat-search">
        <input
          placeholder="ابحث هنا بالرقم"
          type="number"
          v-model="search"
          @input="(checkSearch = false), (errSearch = false), (hasChat = true)"
          @keyup.enter="searchInChat"
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16.414"
          height="16.653"
          viewBox="0 0 16.414 16.653"
        >
          <g id="search" transform="translate(-2.3 -2.061)">
            <circle
              id="Ellipse_274"
              data-name="Ellipse 274"
              cx="6"
              cy="6"
              r="6"
              transform="translate(3.3 3.061)"
              fill="none"
              stroke="#6e6b7b"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
            <line
              id="Line_69"
              data-name="Line 69"
              x1="3.469"
              y1="3.469"
              transform="translate(13.831 13.831)"
              fill="none"
              stroke="#6e6b7b"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
          </g>
        </svg>
      </div>
      <vs-divider class="d-theme-border-grey-light m-0 mb-4" />
      <div class="flex justify-between items-center">
        <h3 class="text-primary px-4 mb-4" v-if="typeOfChat != 1">
          {{ $t("chat") }}
        </h3>
        <div v-else class="flex items-center justify-between px-4 mb-4">
          <h6 class="filter-title">فلتر حسب حالة التذكرة :</h6>
          <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer">
            <div
              style="font-family: 'Cairo', sans-serif !important"
              class="dorpdown-filter d-theme-dark-bg cursor-pointer flex items-center justify-between"
            >
              {{ currentStatus }}
              <feather-icon
                class="ml-1 font-semibold"
                icon="ChevronDownIcon"
                svgClasses="h-4 w-4"
              ></feather-icon>
            </div>
            <vs-dropdown-menu>
              <ul style="min-width: 8rem">
                <div v-for="(titleTicket, index) in ticketStatus" :key="index">
                  <vs-dropdown-item>
                    <li
                      @click="
                        filterStatus(titleTicket.value),
                          (currentStatus = titleTicket.name)
                      "
                      class="flex items-center py-4 px-4 cursor-pointer profile-drop filter-menu"
                    >
                      <span
                        class="status"
                        :style="{
                          backgroundColor: getComplaintStatusbackground(
                            titleTicket.value
                          ),
                        }"
                      ></span>
                      <span
                        class="status-content"
                        :style="{
                          color: getComplaintStatusbackground(
                            titleTicket.value
                          ),
                        }"
                        >{{ titleTicket.name }}</span
                      >
                    </li>
                  </vs-dropdown-item>
                  <vs-divider class="m-0" />
                </div>
              </ul>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
        <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer">
          <svg
            class="mr-4 mb-3 cursor-pointer"
            xmlns="http://www.w3.org/2000/svg"
            width="20.907"
            height="13.938"
            viewBox="0 0 20.907 13.938"
          >
            <path
              id="Icon_material-sort"
              data-name="Icon material-sort"
              d="M4.5,22.938h6.969V20.615H4.5ZM4.5,9v2.323H25.407V9Zm0,8.13H18.438V14.807H4.5Z"
              transform="translate(-4.5 -9)"
              fill="#31006f"
            />
          </svg>
          <vs-dropdown-menu>
            <ul style="min-width: 8rem">
              <div>
                <vs-dropdown-item>
                  <li
                    @click="readUnread('')"
                    class="flex items-center py-4 px-4 cursor-pointer profile-drop filter-menu"
                  >
                    <span class="status-content">جميع الرسائل</span>
                  </li>
                </vs-dropdown-item>
                <vs-divider class="m-0" />
                <vs-dropdown-item>
                  <li
                    @click="readUnread(1)"
                    class="flex items-center py-4 px-4 cursor-pointer profile-drop filter-menu"
                  >
                    <span class="status-content">مقروء</span>
                  </li>
                </vs-dropdown-item>
                <vs-divider class="m-0" />
                <vs-dropdown-item>
                  <li
                    @click="readUnread(0)"
                    class="flex items-center py-4 px-4 cursor-pointer profile-drop filter-menu"
                  >
                    <span class="status-content">غير مقروء</span>
                  </li>
                </vs-dropdown-item>
              </div>
            </ul>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>
      <div
        class="chat-scroll-area pt-4"
        ref="chatScroll"
        style="padding-bottom: 106px"
      >
        <div class="chat__chats-list h-full" :key="$vs.rtl" v-if="hasChat">
          <ul
            class="chat__active-chats bordered-items"
            v-if="
              !loadTicketStatus && search != '' && checkSearch && !errSearch
            "
          >
            <li
              class="cursor-pointer"
              @click.stop="isActiveChatUser(resultSearch[0].id)"
            >
              <chat-contact
                showLastMsg
                :contact="resultSearch[0]"
                :isActiveChatUser="activeChatUser"
                :typeOfChat="typeOfChat"
              ></chat-contact>
            </li>
          </ul>
          <ul
            class="chat__active-chats bordered-items"
            v-else-if="!loadTicketStatus && !errSearch"
          >
            <li
              class="cursor-pointer"
              v-for="(contac, index) in changeChat"
              :key="index"
              @click.stop="isActiveChatUser(contac.id)"
            >
              <chat-contact
                showLastMsg
                :contact="contac"
                :isActiveChatUser="activeChatUser"
                :typeOfChat="typeOfChat"
              ></chat-contact>
            </li>
            <infinite-loading
              :identifier="infiniteId"
              @infinite="infiniteHandler"
            >
              <div slot="no-results" class="font-bold mb-3 text-primary">
                لا يوجد محادثات اخري
              </div>
              <div slot="no-more" class="font-bold mb-3 text-primary">
                لا يوجد محادثات اخري
              </div>
            </infinite-loading>
          </ul>
          <div
            v-else-if="errSearch"
            class="no-chat flex justify-center items-center"
          >
            <img src="./no-chat.png" />
            <h5>
              {{
                this.error_message
                  ? this.error_message
                  : $t("sorryNoMessageYet")
              }}
            </h5>
            <p>{{ $t("chooseOrderYouWantConversation") }}</p>
          </div>
          <!-- {{ error_msg ? error_msg : err_msg }} -->
          <div v-else class="loaderSpinner">
            <SpinnerColor />
          </div>
        </div>
        <div v-else class="no-chat flex justify-center items-center">
          <img src="./no-chat.png" />
          <h5>
            {{
              this.error_message ? this.error_message : $t("sorryNoMessageYet")
            }}
          </h5>
          <p>{{ $t("chooseOrderYouWantConversation") }}</p>
        </div>
      </div>
    </vs-sidebar>

    <!-- RIGHT COLUMN -->
    <div
      class="overlay-display-image"
      v-if="displayUploadImage"
      @click="displayUploadImage = false"
    ></div>
    <div class="display-upload-image" v-show="displayUploadImage">
      <h4>إرسال صورة</h4>
      <SkeletonLoading
        :height="250"
        :borderRadius="4"
        class="mb-4"
        v-if="imgLoading"
      />
      <img
        v-show="!imgLoading"
        :src="displayImage"
        alt="Image"
        @load="imgLoading = false"
      />
      <div class="inputs-display-image">
        <input
          type="text"
          ref="imgInput"
          v-model="imageDescription"
          :placeholder="placeholderDisplayImage"
        />
        <svg
          @click="sendImage()"
          ref="iconImgInput"
          xmlns="http://www.w3.org/2000/svg"
          width="15.848"
          height="16"
          viewBox="0 0 15.848 16"
        >
          <path
            id="Icon_ionic-ios-send"
            data-name="Icon ionic-ios-send"
            d="M19.851,4.539,4.7,11.207a.352.352,0,0,0,.012.638l4.1,2.338a.656.656,0,0,0,.755-.075l8.081-7.035c.054-.046.182-.133.231-.083s-.029.179-.074.233l-6.992,7.952a.67.67,0,0,0-.066.8l2.679,4.338a.347.347,0,0,0,.627-.008L20.317,5A.349.349,0,0,0,19.851,4.539Z"
            transform="translate(-4.503 -4.503)"
            fill="#31006f"
          />
        </svg>
      </div>
    </div>
    <div
      class="chat__bg no-scroll-content chat-content-area border border-solid d-theme-border-grey-light border-t-0 border-r-0 border-b-0"
      :class="{
        'sidebar-spacer--wide': clickNotClose,
        'flex items-center justify-center': activeChatUser === null,
      }"
    >
      <div v-if="load" class="loaderSpinner">
        <SpinnerColor />
      </div>
      <template v-else-if="activeChatUser && load == false && openChatLog">
        <div class="chat__navbar">
          <chat-navbar
            :typeOfChat="typeOfChat"
            :isSidebarCollapsed="!clickNotClose"
            :user-id="activeChatUser"
            :isPinnedProp="isChatPinned"
            @singleTicket="isActiveChatUser"
            @openContactsSidebar="toggleChatSidebar(true)"
            @toggleIsChatPinned="toggleIsChatPinned"
          ></chat-navbar>
        </div>
        <div
          @scroll="handleScrollLog"
          ref="messagesBox"
          id="messages-box"
          :class="
            typeOfChat == 1 &&
            (single_ticket.status == 2 || changeTicketStatus == 2)
              ? 'messages-box-active'
              : ''
          "
          class="border-solid d-theme-border-grey-light"
        >
          <div
            class="chat__log"
            :class="{ chat__log2: load == true }"
            ref="chatLog"
          >
            <chat-log
              @changeOfTicketStatus="changeOfTicketStatus"
              :myMsg="myMsg"
              :load="load"
              :typeOfChat="typeOfChat"
              :userId="activeChatUser"
            ></chat-log>
          </div>
        </div>
        <div
          v-if="!closeTicketForRate"
          class="chat__input flex items-center p-4 bg-white"
          style="
            padding: 0.6rem 1rem !important;
            min-height: 57px;
            z-index: 9999;
            position: relative;
          "
        >
          <span
            role="textbox"
            @keyup.enter="sendMsg"
            :contenteditable="canChat || displayUploadImage"
            ref="typedMessage"
            class="w-full typed-message"
            :class="!canChat && typeOfChat != 1 ? 'dynamic-placeholder' : ''"
            @input="getTypedMessage"
          ></span>
          <div
            class="attach-file ml-4"
            @click="openPopupFiles = !openPopupFiles"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="11.763"
              height="22.456"
              viewBox="0 0 11.763 22.456"
            >
              <path
                id="Icon_ionic-md-attach"
                data-name="Icon ionic-md-attach"
                d="M19.909,7.062V18.825a4.277,4.277,0,1,1-8.555,0V6.527a2.673,2.673,0,1,1,5.347,0v12.3a1.069,1.069,0,0,1-2.139,0V8.666h-1.6V18.825a2.673,2.673,0,0,0,5.347,0V6.527a4.277,4.277,0,0,0-8.555,0v12.3a5.881,5.881,0,0,0,11.763,0V7.062Z"
                transform="translate(-9.75 -2.25)"
                fill="#6e6b7b"
              />
            </svg>
            <div v-show="openPopupFiles && canChat">
              <div class="popup-files">
                <div
                  class="popup-file"
                  v-if="typeOfChat != 1"
                  @click="getLocation()"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13.945"
                    height="16.71"
                    viewBox="0 0 13.945 16.71"
                  >
                    <g
                      id="Icon_feather-map-pin"
                      data-name="Icon feather-map-pin"
                      transform="translate(-3.75 -0.75)"
                    >
                      <path
                        id="Path_3057"
                        data-name="Path 3057"
                        d="M16.945,7.722c0,4.84-6.222,8.988-6.222,8.988S4.5,12.562,4.5,7.722a6.222,6.222,0,1,1,12.445,0Z"
                        transform="translate(0 0)"
                        fill="none"
                        stroke="#000"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                      />
                      <path
                        id="Path_3058"
                        data-name="Path 3058"
                        d="M17.648,12.574A2.074,2.074,0,1,1,15.574,10.5a2.074,2.074,0,0,1,2.074,2.074Z"
                        transform="translate(-4.852 -4.852)"
                        fill="none"
                        stroke="#000"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                      />
                    </g>
                  </svg>
                  موقعك
                </div>
                <div class="popup-file">
                  <input
                    type="file"
                    accept="image/*"
                    @change="previewFiles($event, 1)"
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14.119"
                    height="14.119"
                    viewBox="0 0 14.119 14.119"
                  >
                    <g
                      id="Icon_feather-image"
                      data-name="Icon feather-image"
                      transform="translate(0.6 0.6)"
                    >
                      <path
                        id="Path_3032"
                        data-name="Path 3032"
                        d="M5.908,4.5h9.855A1.408,1.408,0,0,1,17.17,5.908v9.855a1.408,1.408,0,0,1-1.408,1.408H5.908A1.408,1.408,0,0,1,4.5,15.763V5.908A1.408,1.408,0,0,1,5.908,4.5Z"
                        transform="translate(-4.5 -4.5)"
                        fill="none"
                        stroke="#000"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.2"
                      />
                      <path
                        id="Path_3033"
                        data-name="Path 3033"
                        d="M12.612,11.556A1.056,1.056,0,1,1,11.556,10.5,1.056,1.056,0,0,1,12.612,11.556Z"
                        transform="translate(-7.684 -7.684)"
                        fill="none"
                        stroke="#000"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.2"
                      />
                      <path
                        id="Path_3034"
                        data-name="Path 3034"
                        d="M18.763,18.52,15.243,15,7.5,22.743"
                        transform="translate(-6.092 -10.073)"
                        fill="none"
                        stroke="#000"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.2"
                      />
                    </g>
                  </svg>
                  صورة
                </div>
                <div class="popup-file">
                  <input
                    type="file"
                    @change="previewFiles($event, 2)"
                    accept=".pdf, .docx, .xlsx"
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13.87"
                    height="17.038"
                    viewBox="0 0 13.87 17.038"
                  >
                    <g
                      id="Icon_feather-file"
                      data-name="Icon feather-file"
                      transform="translate(-5.4 -2.4)"
                    >
                      <path
                        id="Path_3035"
                        data-name="Path 3035"
                        d="M13.127,3H7.584A1.584,1.584,0,0,0,6,4.584v12.67a1.584,1.584,0,0,0,1.584,1.584h9.5a1.584,1.584,0,0,0,1.584-1.584V8.543Z"
                        transform="translate(0 0)"
                        fill="none"
                        stroke="#000"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.2"
                      />
                      <path
                        id="Path_3036"
                        data-name="Path 3036"
                        d="M19.5,3V8.543h5.543"
                        transform="translate(-6.373 0)"
                        fill="none"
                        stroke="#000"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.2"
                      />
                    </g>
                  </svg>
                  ملف
                </div>
              </div>
            </div>
          </div>
          <vs-button
            style="height: 38px"
            class="bg-primary ml-4"
            type="filled"
            :disabled="!canChat || displayUploadImage"
            @click="sendMsg"
            ref="sendButton"
            >{{ placeholdeInput }}</vs-button
          >
        </div>
      </template>
      <template v-else>
        <div class="flex flex-col items-center justify-center h-full">
          <feather-icon
            icon="MessageCircleIcon"
            class="mb-4 bg-white p-8 shadow-md rounded-full"
            svgClasses="w-16 h-16"
          ></feather-icon>
          <h4
            v-if="windowWidth <= 1200"
            class="show-conversation py-2 px-4 bg-white shadow-md rounded-full cursor-pointer"
            @click.stop="toggleChatSidebar(true)"
          >
            {{ $t("startConversation") }}
          </h4>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import ChatContact from "./ChatContact.vue";
import ChatLog from "./ChatLog.vue";
import ChatNavbar from "./ChatNavbar.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapActions, mapGetters } from "vuex";
import SpinnerColor from "@/views/components/table-components/SpinnerColor2.vue";
import globalMixin from "@/mixins/mixins.js";
import helperMixin from "@/mixins/helper.js";
import imageFormats from "@/mixins/imageFormats.js";
import tour from "@/mixins/tour.js";
import ticketStatus from "@/views/status/ticketStatus";
import SkeletonLoading from "@/layouts/components/SkeletonLoading.vue";

export default {
  mixins: [globalMixin, helperMixin, imageFormats, tour],
  data() {
    return {
      allTypes: ticketStatus.types,
      activeChatUser: null,
      typedMessage: "",
      isChatPinned: false,
      clickNotClose: true,
      isChatSidebarActive: true,
      load: false,
      myMsg: null,
      orderParams: {
        page: 1,
        isRead: "",
        code: "",
      },
      search: "",
      hasChat: true,
      typeOfChat: 1,
      nameOfChat: "محادثات الشكاوي",
      chatOrderContact: [],
      chatTicketContact: [],
      currentStatus: "جميع التذاكر",
      ticketStatus: [
        { name: "جديدة", active: true, value: 0 },
        { name: "جاري الحل", active: true, value: 1 },
        { name: "تم الإغلاق", active: true, value: 2 },
      ],
      ticketParams: {
        statusTicket: "",
        page: 1,
        id: "",
        isRead: "",
      },
      loadTicketStatus: false,
      chatMissions: [],
      resultSearch: "",
      checkSearch: false,
      userId: JSON.parse(localStorage.getItem("userData")).userId,
      unreadMessagesTicket: null,
      unreadMessagesOrder: null,
      unreadMessagesMisssion: null,
      imageTitleName: "قم بإختيار صورة",
      rawFile: "",
      openPopupFiles: false,
      sendFile: false,
      placeholdeInput: "إرسال",
      missionParams: {
        page: 1,
        isRead: "",
        code: "",
      },
      openChatLog: false,
      locationChat: "",
      errSearch: false,
      changeTicketStatus: undefined,
      error_message: "",
      displayUploadImage: false,
      displayImage: "",
      imageDescription: "",
      placeholderDisplayImage: "اضف عنوانا للصوة",
      steps: [
        {
          target: '[data-chat-tour-step="1"]', // We're using document.querySelector() under the hood
          header: {
            title: "متابعة الطرود",
          },
          content: `يمكنك متابعة محادثات الطرود الخاصة بك من هنا`,
        },
        {
          target: '[data-chat-tour-step="2"]',
          header: {
            title: "متابعة المهمات",
          },
          content: `يمكنك متابعة محادثات المهمات الخاصة بك من هنا`,
        },
        {
          target: '[data-chat-tour-step="3"]',
          header: {
            title: "متابعة تذاكر الدعم",
          },
          content: `يمكنك متابعة محادثات تذاكر الدعم الخاصة بك من هنا`,
        },
      ],
      infiniteId: +new Date(),
      isScrolling: "",
      imgLoading: false,
    };
  },
  watch: {
    windowWidth() {
      this.setSidebarWidth();
    },
    typeOfChat: {
      handler: function () {
        this.$refs.chatScroll.scrollTop = 0;
        this.search = "";
        if (this.typeOfChat == 1) {
          this.dataHasChat(this.chatTicketContact);
        } else if (this.typeOfChat == 2) {
          this.dataHasChat(this.chatOrderContact);
        } else if (this.typeOfChat == 3) {
          this.dataHasChat(this.chatMissions);
        }
      },
    },
  },
  computed: {
    ...mapGetters("chat", [
      "conversations",
      "conversations_mission",
      "info_chat",
      "info_chat_mission",
      "err_msg",
    ]),
    ...mapGetters("ticket", [
      "tickets",
      "single_ticket",
      "count_unread_ticket",
      "error_msg",
    ]),
    ...mapGetters('userData', ['new_support_system_user']),

    scrollbarTag() {
      return this.$store.getters.scrollbarTag;
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    changeChat() {
      this.infiniteId += 1;
      if (this.typeOfChat == 2) {
        return this.chatOrderContact;
      } else if (this.typeOfChat == 1) {
        return this.chatTicketContact;
      } else if (this.typeOfChat == 3) {
        return this.chatMissions;
      }
    },
    canChat() {
      if (this.typeOfChat == 2) {
        if (this.info_chat.can_send != true || this.sendFile) {
          return false;
        } else {
          return true;
        }
      } else if (this.typeOfChat == 3) {
        if (this.info_chat_mission.can_send != true || this.sendFile) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    showChatForCompany() {
      if (
        this.$acl.not.check("isPublicCompany") ||
        JSON.parse(localStorage.getItem("userData")).company.show_chat
      ) {
        return true;
      } else {
        return false;
      }
    },
    chats() {
      if (this.showChatForCompany) {
        if(this.new_support_system_user){
          return [
            { name: "محادثات الطرود", value: 2 },
            { name: "محادثات المهمات", value: 3 },
          ]
        }else{
          return [
            { name: "محادثات الطرود", value: 2 },
            { name: "محادثات المهمات", value: 3 },
            { name: "محادثات الشكاوي", value: 1 },
          ];
        }
      } else {
        return [{ name: "محادثات الشكاوي", value: 1 }];
      }
    },
    closeTicketForRate() {
      if (this.typeOfChat == 1) {
        if (this.single_ticket.status == 2 || this.changeTicketStatus == 2) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
  methods: {
    // CHAT
    ...mapActions("chat", [
      "fetchConversation",
      "fetchChat",
      "postMessage",
      "fetchConversationMission",
      "fetchChatMission",
      "postMessageMission",
    ]),
    ...mapActions("ticket", [
      "allTickets",
      "singleTicket",
      "replyTicket",
      "countUnreadTicket",
    ]),
    // get ticket & order & mission conversations
    async infiniteHandler($state) {
      if (this.typeOfChat == 1) {
        if (this.tickets.length != 0) {
          this.ticketParams.page++;
          this.ticketParams.id = "";
          await this.allTickets(this.ticketParams);
          this.chatTicketContact = this.chatTicketContact.concat(this.tickets);
          $state.loaded();
        } else {
          $state.complete();
        }
      } else if (this.typeOfChat == 2) {
        if (this.conversations.length != 0) {
          this.orderParams.page++;
          this.orderParams.code = "";
          await this.fetchConversation(this.orderParams);
          this.chatOrderContact = this.chatOrderContact.concat(
            this.conversations
          );
          $state.loaded();
        } else {
          $state.complete();
        }
      } else if (this.typeOfChat == 3) {
        if (this.conversations_mission.length != 0) {
          this.missionParams.page++;
          this.missionParams.code = "";
          await this.fetchConversationMission(this.missionParams);
          this.chatMissions = this.chatMissions.concat(
            this.conversations_mission
          );
          $state.loaded();
        } else {
          $state.complete();
        }
      }
    },
    // check type of message (text or file) and send message
    async sendMsg(e) {
      if (!this.typedMessage && !this.rawFile && !this.sendFile) {
        return false;
      } else if (!e.shiftKey) {
        this.sendFile = true;
        this.placeholdeInput = "جاري الإرسال ...";
        let obj = {
          id: this.activeChatUser,
          message: this.typedMessage,
          fileSize: "",
          fileName: "",
        };
        if (this.rawFile != "") {
          obj.image = this.rawFile;
          obj.fileSize = this.bytesToSize(this.rawFile.size);
          obj.fileName = this.rawFile.name;
        }
        this.myMsg = this.typedMessage;
        this.typedMessage = "";
        if (this.typeOfChat == 2) {
          obj.longitude = "";
          (obj.latitude = ""), await this.postMessage(obj);
        } else if (this.typeOfChat == 1) {
          await this.replyTicket(obj);
          this.rawFile = "";
          this.imageTitleName = "قم بإختيار صورة";
        } else if (this.typeOfChat == 3) {
          obj.longitude = "";
          (obj.latitude = ""), await this.postMessageMission(obj);
        }
        this.$refs.typedMessage.innerText = "";
        this.sendFile = false;
        this.placeholdeInput = "إرسال";
      }
    },
    // get text of message and remove all whitespace from it
    getTypedMessage(e) {
      let text = e.target.innerText;
      text = text.replace(/\s/g, "");
      if (text) {
        this.typedMessage = e.target.innerText;
      }
    },
    // get size of file
    bytesToSize(bytes) {
      const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes === 0) return "n/a";
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
      if (i === 0) return `${bytes} ${sizes[i]})`;
      return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
    },
    // choose image and display it for user
    async previewFiles(e, type) {
      this.imgLoading = true;
      const files = e.target.files;
      this.rawFile = files[0];
      const typeFile = this.rawFile.type.split("/").pop();
      if (type == 1) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.displayImage = e.target.result;
        };
        reader.readAsDataURL(this.rawFile);
        this.displayUploadImage = true;
      }
      if (type == 2) {
        if (!this.imageFormats.includes(typeFile)) {
          this.sendFile = true;
          this.placeholdeInput = "جاري إرسال الملف ...";
          let obj = {
            id: this.activeChatUser,
            message: "",
            image: this.rawFile,
            fileSize: this.bytesToSize(this.rawFile.size),
            fileName: this.rawFile.name,
          };
          if (this.typeOfChat == 1) {
            await this.replyTicket(obj);
          } else if (this.typeOfChat == 2) {
            await this.postMessage(obj);
          } else if (this.typeOfChat == 3) {
            await this.postMessageMission(obj);
          }
          this.rawFile = "";
          this.sendFile = false;
          this.placeholdeInput = "إرسال";
        }
      }
      this.imageTitleName = this.rawFile.name;
    },
    // send image
    async sendImage() {
      let obj = {
        id: this.activeChatUser,
        message: this.imageDescription,
        image: this.rawFile,
        fileSize: this.bytesToSize(this.rawFile.size),
        fileName: this.rawFile.name,
      };
      this.placeholderDisplayImage = "جاري الارسال";
      this.$refs.iconImgInput.style.cursor = "wait";
      this.$refs.imgInput.style.cursor = "wait";
      if (this.typeOfChat == 2) {
        obj.longitude = "";
        (obj.latitude = ""), await this.postMessage(obj);
        this.rawFile = "";
      } else if (this.typeOfChat == 1) {
        await this.replyTicket(obj);
        this.rawFile = "";
      } else if (this.typeOfChat == 3) {
        obj.longitude = "";
        (obj.latitude = ""), await this.postMessageMission(obj);
        this.rawFile = "";
      }
      this.$refs.iconImgInput.style.cursor = "default";
      this.$refs.imgInput.style.cursor = "default";
      this.placeholderDisplayImage = "اضف عنوانا للصورة";
      this.displayUploadImage = false;
    },
    toggleIsChatPinned(value) {
      this.isChatPinned = value;
    },
    setSidebarWidth() {
      if (this.windowWidth < 1200) {
        this.isChatSidebarActive = this.clickNotClose = false;
      } else {
        this.isChatSidebarActive = this.clickNotClose = true;
      }
    },
    toggleChatSidebar(value = false) {
      if (!value && this.clickNotClose) return;
      this.isChatSidebarActive = value;
    },
    async isActiveChatUser(id) {
      this.openChatLog = true;
      this.load = true;
      this.activeChatUser = id;
      if (this.typeOfChat == 1) {
        await this.singleTicket(id);
      } else if (this.typeOfChat == 2) {
        await this.fetchChat(id);
      } else if (this.typeOfChat == 3) {
        await this.fetchChatMission(id);
      }
      this.load = false;
    },
    handleScrollLog() {
      clearTimeout(this.isScrolling);
      // console.log("Scrolling");
      const messageBox = document.getElementById("messages-box");
      const dateLabels = document.querySelectorAll(".date");
      const dateActive = document.querySelector(".date-active");
      // dateActive.style.display = "block";
      let currentLabel = null;
      dateLabels.forEach((dateLabel) => {
        if (messageBox.scrollTop >= dateLabel.offsetTop) {
          currentLabel = dateLabel;
        }
      });
      if (currentLabel) {
        dateActive.innerText = currentLabel.innerText;
      }
      this.isScrolling = setTimeout(() => {
        // console.log("Stop Scrolling");
      }, 2000);
    },
    // check if has chat or not
    dataHasChat(data) {
      if (data.length != 0) {
        this.hasChat = true;
      } else {
        this.hasChat = false;
      }
    },
    // filter ticket by status
    async filterStatus(status) {
      this.ticketParams.statusTicket = status;
      this.ticketParams.id = "";
      this.ticketParams.page = 1;
      this.loadTicketStatus = true;
      await this.allTickets(this.ticketParams);
      this.chatTicketContact = this.tickets.slice();
      this.loadTicketStatus = false;
    },
    // search in chat (ticket && order && mission)
    async searchInChat() {
      this.loadTicketStatus = true;
      if (this.typeOfChat == 1) {
        this.ticketParams.status = "";
        this.ticketParams.page = "";
        this.ticketParams.id = this.search;
        await this.allTickets(this.ticketParams);
        this.dataHasChat(this.tickets);
        this.error_message = "لا يوجد تذكرة بهذا الكود";
        this.resultSearch = this.tickets.slice();
      } else if (this.typeOfChat == 2) {
        (this.orderParams.page = 1), (this.orderParams.isRead = "");
        this.orderParams.code = this.search;
        await this.fetchConversation(this.orderParams);
        if (this.err_msg) {
          this.errSearch = true;
          this.error_message = "لا يوجد طرد بهذا الكود";
        } else {
          this.errSearch = false;
          this.dataHasChat(this.conversations);
          this.error_message = "";
        }
        this.resultSearch = this.conversations.slice();
      } else if (this.typeOfChat == 3) {
        (this.missionParams.page = 1), (this.missionParams.isRead = "");
        this.missionParams.code = this.search;
        await this.fetchConversationMission(this.missionParams);
        if (this.err_msg) {
          this.errSearch = true;
          this.error_message = "لا يوجد مهمة بهذا الكود";
        } else {
          this.errSearch = false;
          this.error_message = "";
          this.dataHasChat(this.conversations_mission);
        }
        this.resultSearch = this.conversations_mission.slice();
      }
      this.loadTicketStatus = false;
      this.checkSearch = true;
    },
    // update ticket contact chat "realtime"
    updateChatContactTicket(obj, array, deleteObj) {
      let index = array.findIndex((n) => n.id == obj.id);
      obj.id = parseFloat(obj.id);
      array.splice(index, 1);
      if (!deleteObj) {
        if (
          this.ticketParams.statusTicket == "" ||
          this.ticketParams.statusTicket == parseFloat(obj.status)
        ) {
          obj.status = parseFloat(obj.status);
          obj.seen_by_client = parseFloat(obj.seen_by_client);
          obj.type = parseFloat(obj.type);
          obj.rate = parseFloat(obj.rate);
          array.unshift(obj);
        }
      }
    },
    // update order contact chat "realtime"
    updateChatContactOrder(obj, array) {
      let index = array.findIndex((n) => n.id == obj.id);
      array.splice(index, 1);
      array.unshift(obj);
    },
    tabChat(chat) {
      if (!this.loadTicketStatus) {
        this.activeChatUser = null;
        this.openChatLog = true;
        this.errSearch = false;
        this.typeOfChat = chat.value;
        this.nameOfChat = chat.name;
      }
    },
    // filter by read and unread
    async readUnread(type) {
      this.loadTicketStatus = true;
      if (this.typeOfChat == 1) {
        this.ticketParams.page = 1;
        this.ticketParams.isRead = type;
        await this.allTickets(this.ticketParams);
        this.chatTicketContact = this.tickets.slice();
        this.dataHasChat(this.tickets);
        this.ticketParams.isRead = "";
      } else if (this.typeOfChat == 2) {
        this.orderParams.isRead = type;
        this.orderParams.page = 1;
        await this.fetchConversation(this.orderParams);
        this.chatOrderContact = this.conversations.slice();
        this.dataHasChat(this.conversations);
        this.orderParams.isRead = "";
      } else if (this.typeOfChat == 3) {
        this.missionParams.isRead = type;
        this.missionParams.page = 1;
        await this.fetchConversationMission(this.missionParams);
        this.chatMissions = this.conversations_mission.slice();
        this.dataHasChat(this.conversations_mission);
        this.missionParams.isRead = "";
      }
      this.loadTicketStatus = false;
    },
    // get location of user and send it
    async getLocation() {
      this.sendFile = true;
      this.placeholdeInput = "جاري إرسال الموقع ...";
      await window.navigator.geolocation.getCurrentPosition(
        async (position) => {
          this.locationChat = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          let obj = {
            id: this.activeChatUser,
            message: "",
            image: "",
            fileSize: "",
            fileName: "",
            longitude: this.locationChat.lng,
            latitude: this.locationChat.lat,
          };
          if (this.typeOfChat == 2) {
            await this.postMessage(obj);
          } else if (this.typeOfChat == 3) {
            await this.postMessageMission(obj);
          }
          this.sendFile = false;
          this.placeholdeInput = "إرسال";
        }
      );
    },
    // listen and changet status of ticket
    changeOfTicketStatus(status) {
      this.changeTicketStatus = status;
    },
  },
  components: {
    VuePerfectScrollbar,
    ChatContact,
    ChatNavbar,
    ChatLog,
    SpinnerColor,
    SkeletonLoading,
  },
  mounted() {
    this.new_support_system_user? this.typeOfChat = 2 : this.typeOfChat = 1;
    this.$socket.emit("join", {
      name: `ticket-changes-${this.userId}-created`,
    });
    this.$socket.emit("join", {
      name: `order-conversation-${this.userId}-created`,
    });
    this.$socket.emit("join", {
      name: `mission-conversation-${this.userId}-created`,
    });
    this.$socket.emit("join", { name: `unreaded-${this.userId}-created` });
    this.$socket.emit("join", {
      name: `unreaded-order-${this.userId}-created`,
    });
    this.$socket.emit("join", {
      name: `unreaded-mission-${this.userId}-created`,
    });
    this.$socket.on(`ticket-changes-${this.userId}-created`, (data) => {
      let obj = data.body;
      if (
        data.body.type == this.allTypes.gratitude ||
        data.body.type == this.allTypes.suggestion
      ) {
        this.updateChatContactTicket(obj, this.chatTicketContact, true);
      } else {
        this.updateChatContactTicket(obj, this.chatTicketContact, false);
        if (this.resultSearch != "") {
          this.updateChatContactTicket(obj, this.resultSearch, false);
        }
      }
    });
    this.$socket.on(`order-conversation-${this.userId}-created`, (data) => {
      let obj = data.body;
      this.updateChatContactOrder(obj, this.chatOrderContact);
    });
    this.$socket.on(`mission-conversation-${this.userId}-created`, (data) => {
      let obj = data.body;
      this.updateChatContactOrder(obj, this.chatMissions);
    });
    this.$socket.on(`unreaded-${this.userId}-created`, (data) => {
      this.unreadMessagesTicket = data.body.tickets;
    });
    this.$socket.on(`unreaded-order-${this.userId}-created`, (data) => {
      this.unreadMessagesOrder = data.body.orders;
    });
    this.$socket.on(`unreaded-mission-${this.userId}-created`, (data) => {
      this.unreadMessagesMisssion = data.body.missions;
    });
    let addOrderTour = localStorage.getItem("addOrderTour");
    if (addOrderTour !== null) {
      if (this.runWalkthrough()) {
        localStorage.removeItem("addOrderTour");
        // this.startTour("chatTour");
        this.$watch(
          () => {
            return this.$tours.chatTour.currentStep;
          },
          (val) => {
            if (val == 2) {
              const nextStep = document.querySelector(".v-step__button");
              nextStep.addEventListener("click", () => {
                this.$router.push({ path: "/setting" });
              });
            }
          }
        );
      }
    }
  },
  unmounted() {
    this.$socket.emit("leave", {
      name: `ticket-changes-${this.userId}-created`,
    });
    this.$socket.emit("leave", {
      name: `order-conversation-${this.userId}-created`,
    });
    this.$socket.emit("leave", {
      name: `mission-conversation-${this.userId}-created`,
    });
    this.$socket.emit("leave", { name: `unreaded-${this.userId}-created` });
    this.$socket.emit("leave", {
      name: `unreaded-order-${this.userId}-created`,
    });
    this.$socket.emit("leave", {
      name: `unreaded-mission-${this.userId}-created`,
    });
    this.$socket.off(`ticket-changes-${this.userId}-created`);
    this.$socket.off(`order-conversation-${this.userId}-created`);
    this.$socket.off(`mission-conversation-${this.userId}-created`);
    this.$socket.off(`unreaded-${this.userId}-created`);
    this.$socket.off(`unreaded-order-${this.userId}-created`);
    this.$socket.off(`unreaded-mission-${this.userId}-created`);
  },
  async created() {
    this.loadTicketStatus = true;
    this.setSidebarWidth();
    // CHAT
    await this.countUnreadTicket();
    await this.allTickets(this.ticketParams);
    this.chatTicketContact = this.tickets.slice();
    if (this.showChatForCompany) {
      await this.fetchConversation(this.orderParams);
      this.chatOrderContact = this.conversations.slice();
      await this.fetchConversationMission(this.missionParams);
      this.chatMissions = this.conversations_mission.slice();
    }
    const id = parseFloat(this.$route.params.id);
    if (id) {
      if (this.$route.query.type == 3) {
        this.typeOfChat = 3;
      } else if (this.$route.query.type == 2) {
        this.typeOfChat = 2;
      } else if (this.$route.query.type == 1) {
        this.typeOfChat = 1;
      }
      this.isActiveChatUser(id);
    }
    this.loadTicketStatus = false;
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/chat.scss";
</style>

<style lang="scss" scoped>
.loaderSpinner {
  width: 100% !important;
  background-color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
}
</style>
