import ticketStatus from "@/views/status/ticketStatus";
export default {
  data() {
    return {
      allTypes: ticketStatus.types,
    };
  },
  methods: {
    returnNameTicket(type) {
      if (type == this.allTypes.inquiry) {
        return "إستفسار رقم ";
      } else if (type == this.allTypes.complaint) {
        return "شكوي رقم ";
      } else if (type == this.allTypes.gratitude) {
        return "شكر رقم ";
      } else if (type == this.allTypes.suggestion) {
        return "إقتراح رقم ";
      } else if (type == this.allTypes.connectWithClient) {
        return "تواصل مع عميل رقم ";
      } else if (type == this.allTypes.request) {
        return "طلب رقم ";
      }
    },
    returnStatusTicket(type) {
      if (type == this.allTypes.inquiry) {
        return "حالة الإستفسار ";
      } else if (type == this.allTypes.complaint) {
        return "حالة الشكوي ";
      } else if (type == this.allTypes.gratitude) {
        return "حالة الشكر ";
      } else if (type == this.allTypes.suggestion) {
        return "حالة الإقتراح ";
      } else if (type == this.allTypes.connectWithClient) {
        return "حالة التذكرة ";
      } else if (type == this.allTypes.request) {
        return "حالة الطلب ";
      }
    },
    imgTicket(type, id, status, isActiveChatUser, isRead) {
      if (
        (type == this.allTypes.inquiry || type == this.allTypes.request) &&
        status == 2
      ) {
        return require("@/assets/images/elements/read-inquiry.svg");
      } else if (type == this.allTypes.complaint && status == 2) {
        return require("@/assets/images/elements/read-complaint.svg");
      } else if (id == isActiveChatUser && type == this.allTypes.complaint) {
        return require("@/assets/images/elements/click-complaint.svg");
      } else if (
        id == isActiveChatUser &&
        (type == this.allTypes.inquiry || type == this.allTypes.request)
      ) {
        return require("@/assets/images/elements/click-inquiry.svg");
      } else if (
        (isRead == 0 && type == this.allTypes.complaint) ||
        (isRead == 1 && type == this.allTypes.complaint)
      ) {
        return require("@/assets/images/elements/unread-complaint.svg");
      } else if (
        type == this.allTypes.inquiry ||
        type == this.allTypes.request
      ) {
        return require("@/assets/images/elements/inquiry.svg");
      } else if (type == 5 && id == isActiveChatUser) {
        return require("@/assets/images/elements/click-user.svg");
      } else if (type == 5) {
        return require("@/assets/images/elements/user5.svg");
      }
    },
  },
};
