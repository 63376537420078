<template>
    <div @click="checkMsg()" class="chat__contact flex items-center px-2 pt-4 pb-2"
      :style="[contact.id==isActiveChatUser ? {'background': `transparent linear-gradient(271deg, ${colorMain} 0%, ${colorMain} 100%) 0% 0% no-repeat padding-box`}: {}]"
      :class="{'text-white shadow-lg': contact.id==isActiveChatUser, 'unreadchat': isRead == 0}">
        <div class="contact__avatar mr-1 text-center">
          <img src="@/assets/images/elements/user2.svg" class="mr-4" alt="User" width="39.75" v-if="typeOfChat != 1">
          <div v-else>
            <div class="icon-complaint" :class="{'bg-white': contact.id == isActiveChatUser, 'bg-primary': isRead == 0, 'icon-complaint-close': (contact.status == 2 && contact.id != isActiveChatUser)}">
              <img :src="imgTicket(contact.type, contact.id, contact.status, isActiveChatUser, isRead)" width="26">
              <span class="status">
                <span :style="{ backgroundColor: getComplaintStatusbackground(contact.status) }"></span>
              </span>
            </div>
          </div>
        </div>
        <div class="contact__container w-full flex items-center justify-between overflow-hidden">
            <div class="contact__info flex flex-col truncate w-full">
                <div class="flex justify-between">
                  <h5 class="chat-code" :class="{'font-bold text-primary': isRead == 0, 'text-white font-normal': contact.id==isActiveChatUser}">
                  {{ typeOfChat == 2 ? 'طرد رقم ' + contact.code : typeOfChat == 1 ? returnNameTicket(contact.type) + '#' + contact.id : 'مهمة رقم ' + contact.code }}
                  </h5>
                  <span style="color: #B9B9C3;">{{new Date().setHours(0,0,0,0) == new Date(contact.last_message_date).setHours(0,0,0,0) ? new Date(contact.last_message_date).toLocaleTimeString('ar-EG', { hour: '2-digit', minute: '2-digit' }) : new Date(contact.updated_at).toLocaleDateString('ar-EG',{ hour: '2-digit', minute: '2-digit' }).split(' ').slice(1, 3).join(' ')}}</span>
     
                </div>
                <div v-if="typeOfChat == 1" class="flex items-center justify-between mt-2">
                  <span class="truncate font-12" v-if="contact.status != 2 && contact.rate == 0">{{contact.last_message_text ? contact.last_message_text : !contact.last_message_file ? "" : checkImageFormat(contact.last_message_file) ? "صورة" : " ملف مرفق"}}</span>
                  <span v-else-if="contact.rate == 0" class="truncate font-12 flex items-center">
                    {{ 'برجاء تقييم الموظف المسئول' }} <img style="width: 22px;" src="@/assets/images/elements/rate1.svg" class="ml-2" />
                  </span>
                  <span class="font-12" v-else>{{contact.last_message_text ? contact.last_message_text : !contact.last_message_file ? "" : checkImageFormat(contact.last_message_file) ? "صورة" : " ملف مرفق"}}</span>
                  <img style="width: 22px;" v-if="contact.id == isActiveChatUser && contact.rate != 0" :src="rates[(contact.rate)-1].imgSrc" :class="{'active-rate': contact.id == isActiveChatUser}">
                  <img style="width: 22px;" v-else-if="contact.rate != 0" :src="rates[(contact.rate)-1].imgSrc" :class="rates[(contact.rate)-1].value == contact.rate ? rates[(contact.rate)-1].className : ''" />
                </div>
                <span class="truncate font-12" v-else>{{ contact.message && contact.message != " " ? contact.message : !contact.file ? "موقع علي الخريطة" : checkImageFormat(contact.file) ? "صورة" : "ملف مرفق" }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import getColorStatus from '@/mixins/helper.js'
// import ticketStatus from '@/views/status/ticketStatus'
import imageFormats from '@/mixins/imageFormats.js'
import ticketsMixins from '@/mixins/tickets.js'

export default {
  mixins: [getColorStatus, imageFormats, ticketsMixins],
  props: {
    contact          : { type: Object,  required: true },
    isActiveChatUser : { type: Number },
    typeOfChat       : { type: Number }
  },
  data () {
    return {
      isRead: this.contact.is_read || this.contact.seen_by_client,
      // allTypes: ticketStatus.types,
      rates: [
        {value: 1, imgSrc: require('@/assets/images/elements/rate5.svg'), className: 'active-rate5', name: 'غير راضي جدا'},
        {value: 2, imgSrc: require('@/assets/images/elements/rate4.svg'), className: 'active-rate4', name: 'غير راضي'},
        {value: 3, imgSrc: require('@/assets/images/elements/rate3.svg'), className: 'active-rate3', name: 'عادي'},
        {value: 4, imgSrc: require('@/assets/images/elements/rate2.svg'), className: 'active-rate2', name: 'راضي'},
        {value: 5, imgSrc: require('@/assets/images/elements/rate1.svg'), className: 'active-rate1', name: 'راضي جدا'}
      ],
      colorMain: process.env.VUE_APP_CONFIGS_MAIN_COLOR,
      colorSecond: process.env.VUE_APP_CONFIGS_SECONDERY_COLOR
    }
  },
  watch: {
    typeOfChat: {
      handler: function() {
        this.checkRead()
      }
    },
    contact: {
      handler: function() {
        this.checkRead()
      }
    }
  },
  mounted() {
    console.log(this.$props);
    this.contact.last_message_date.split("T")[1]
  },
  methods: {
    checkMsg (){
      this.isRead = 1
    },
    checkRead(){
      if(this.typeOfChat == 1){
        this.isRead = this.contact.seen_by_client
      }else if (this.typeOfChat == 2){
        this.isRead = this.contact.is_read
      }
      else if(this.typeOfChat == 3) {
        this.isRead = this.contact.is_read
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/vuexy/_variables.scss';

.con-vs-chip {
    font-size: 1rem !important;
    width: 5rem !important;
}
.chat__contact__meta{
    margin-right: 0.5rem !important;
    width: 25.66667% !important;
    align-items: center !important;
}
.con-vs-chip.number .vs-chip--text {
    font-weight: 400 !important;
}
.unreadchat{
  background-color: #31006F1A;
}
.no-chat{
  flex-direction: column;
}
.icon-complaint{
  display: flex;
  height: 55px;
  width: 55px;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  margin-right: 12px;
  position: relative;
  background-color: $mainColor1;
}
.icon-complaint-close{
  background-color: #f0f0f0 !important;
}
.chat-code{
  font-size: 14px;
  line-height: 21px;
}
</style>

<style lang="scss" scoped>
@import '@/assets/scss/vuexy/_rate.scss';
.status{
  width: 14px;
  height: 14px;
  border-radius: 50%;
  position: absolute;
  bottom: 0px;
  left: 3px;
  background-color: #FFF;
  line-height: 14px;
  span{
    display: inline-block;
    border-radius: 50%;
    width: 10px;
    height: 10px;
  }
}
.font-12 {
  font-size: 12px;
}
</style>