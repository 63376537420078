<template>
  <div v-if="userId != null" class="chat__header">
    <vs-navbar class="p-4 flex navbar-custom" color="white" type="flat">
      <div class="relative flex w-full">
        <feather-icon
          icon="MenuIcon"
          class="mr-4 cursor-pointer"
          v-if="isSidebarCollapsed"
          @click.stop="$emit('openContactsSidebar')"
        />
        <div
          class="data-header w-full flex justify-between"
          v-if="typeOfChat == 2"
        >
          <div class="number-chat flex items-center">
            <i>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17.57"
                height="19.527"
                viewBox="0 0 17.57 19.527"
              >
                <g id="package" transform="translate(-2.215 -1.225)">
                  <line
                    id="Line_385"
                    data-name="Line 385"
                    x1="8"
                    y1="4.613"
                    transform="translate(7 3.965)"
                    fill="none"
                    stroke="#31006f"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                  />
                  <path
                    id="Path_958"
                    data-name="Path 958"
                    d="M19,14.552V7.379a1.8,1.8,0,0,0-.889-1.551L11.889,2.242a1.765,1.765,0,0,0-1.778,0L3.889,5.828A1.8,1.8,0,0,0,3,7.379v7.172A1.8,1.8,0,0,0,3.889,16.1l6.222,3.586a1.765,1.765,0,0,0,1.778,0L18.111,16.1A1.8,1.8,0,0,0,19,14.552Z"
                    fill="none"
                    stroke="#31006f"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                  />
                  <path
                    id="Path_959"
                    data-name="Path 959"
                    d="M3.27,6.96l7.76,4.489L18.79,6.96"
                    transform="translate(-0.03 -0.5)"
                    fill="none"
                    stroke="#31006f"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                  />
                  <line
                    id="Line_386"
                    data-name="Line 386"
                    y1="8.96"
                    transform="translate(11 11.042)"
                    fill="none"
                    stroke="#31006f"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                  />
                </g>
              </svg>
            </i>
            <div>
              <h6>شات طرد رقم :</h6>
              <h5
                @click="
                  $router
                    .push(`/OrderDetails/` + info_chat.code)
                    .catch(() => {})
                "
              >
                {{ info_chat.code }}
              </h5>
            </div>
          </div>
          <div class="data-chat flex items-center">
            <div class="inner-data-chat">
              <h6>اسم المرسل إليه :</h6>
              <h5>{{ info_chat.receiver }}</h5>
            </div>
            <div class="inner-data-chat">
              <h6>اسم الكابتن :</h6>
              <h5>{{ info_chat.captain }}</h5>
            </div>
            <div class="inner-data-chat">
              <h6>الفرع المسئول :</h6>
              <h5>{{ info_chat.branch }}</h5>
            </div>
          </div>
        </div>
        <div
          class="data-header w-full flex justify-between"
          v-else-if="typeOfChat == 1"
        >
          <div class="number-chat number-chat-ticket flex items-center">
            <i>
              <img
                :src="
                  imgTicket(
                    ticketType,
                    single_ticket.id,
                    status,
                    single_ticket.id,
                    1
                  )
                "
              />
            </i>
            <div>
              <h6>
                {{ returnNameTicket(ticketType) }}
                {{ single_ticket.id }}
              </h6>
              <h5 v-if="ticketType == allTypes.complaint">
                بخصوص :
                {{ single_ticket.complain.complaint_type_title.title_ar }}
              </h5>
            </div>
          </div>
          <div class="data-chat flex items-center">
            <div class="inner-data-chat" v-if="status == 1">
              <h6>الموظف المسئول</h6>
              <h5>{{ assignedUser }}</h5>
            </div>
            <div class="inner-data-chat">
              <h6>{{ returnStatusTicket(ticketType) }}</h6>
              <h5 :style="{ color: getComplaintStatusbackground(status) }">
                {{ ticketStatus }}
              </h5>
            </div>
            <div class="inner-data-chat" v-if="ticketType == 2">
              <div
                v-if="single_ticket.complain.complaint_type.related_model == 0"
              >
                <h6>رقم الطرد :</h6>
                <h5
                  class="cursor-pointer"
                  @click="
                    $router
                      .push(`/OrderDetails/` + single_ticket.complain.entity_id)
                      .catch(() => {})
                  "
                >
                  <u>{{ single_ticket.complain.entity_id }}</u>
                </h5>
              </div>
              <div
                v-else-if="
                  single_ticket.complain.complaint_type.related_model == 1
                "
              >
                <h6>اسم الفرع :</h6>
                <h5>{{ single_ticket.complain.entity_name }}</h5>
              </div>
            </div>
            <div class="inner-data-chat">
              <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer">
                <div
                  style="font-family: 'Cairo', sans-serif !important"
                  class="cursor-pointer flex items-center justify-between"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="5"
                    viewBox="0 0 19 5"
                  >
                    <g
                      id="Icon_feather-more-horizontal"
                      data-name="Icon feather-more-horizontal"
                      transform="translate(1.5 1.5)"
                    >
                      <path
                        id="Path_2513"
                        data-name="Path 2513"
                        d="M18.5,17.5a1,1,0,1,1-1-1A1,1,0,0,1,18.5,17.5Z"
                        transform="translate(-9.5 -16.5)"
                        fill="#31006F"
                        stroke="#31006F"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="3"
                      />
                      <path
                        id="Path_2514"
                        data-name="Path 2514"
                        d="M29,17.5a1,1,0,1,1-1-1A1,1,0,0,1,29,17.5Z"
                        transform="translate(-13 -16.5)"
                        fill="#31006F"
                        stroke="#31006F"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="3"
                      />
                      <path
                        id="Path_2515"
                        data-name="Path 2515"
                        d="M8,17.5a1,1,0,1,1-1-1A1,1,0,0,1,8,17.5Z"
                        transform="translate(-6 -16.5)"
                        fill="#31006F"
                        stroke="#31006F"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="3"
                      />
                    </g>
                  </svg>
                </div>
                <vs-dropdown-menu>
                  <ul style="min-width: 8rem">
                    <vs-dropdown-item
                      @click="
                        $router
                          .push('/Log/' + single_ticket.id + '/ticket')
                          .catch(() => {})
                      "
                    >
                      <li
                        class="flex items-center py-4 px-4 cursor-pointer profile-drop filter-menu"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="mr-2"
                          width="14.844"
                          height="14.844"
                          viewBox="0 0 22.844 22.844"
                        >
                          <path
                            id="Icon_feather-message-square"
                            data-name="Icon feather-message-square"
                            d="M25.344,18.4a2.316,2.316,0,0,1-2.316,2.316H9.132L4.5,25.344V6.816A2.316,2.316,0,0,1,6.816,4.5H23.028a2.316,2.316,0,0,1,2.316,2.316Z"
                            transform="translate(-3.5 -3.5)"
                            fill="none"
                            stroke="#31006F"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                          />
                        </svg>
                        سجل التذكرة
                      </li>
                    </vs-dropdown-item>
                  </ul>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>
          </div>
        </div>
        <div
          class="data-header w-full flex justify-between"
          v-else-if="typeOfChat == 3"
        >
          <div class="number-chat flex items-center">
            <i>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17.57"
                height="19.527"
                viewBox="0 0 17.57 19.527"
              >
                <g id="package" transform="translate(-2.215 -1.225)">
                  <line
                    id="Line_385"
                    data-name="Line 385"
                    x1="8"
                    y1="4.613"
                    transform="translate(7 3.965)"
                    fill="none"
                    stroke="#31006f"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                  />
                  <path
                    id="Path_958"
                    data-name="Path 958"
                    d="M19,14.552V7.379a1.8,1.8,0,0,0-.889-1.551L11.889,2.242a1.765,1.765,0,0,0-1.778,0L3.889,5.828A1.8,1.8,0,0,0,3,7.379v7.172A1.8,1.8,0,0,0,3.889,16.1l6.222,3.586a1.765,1.765,0,0,0,1.778,0L18.111,16.1A1.8,1.8,0,0,0,19,14.552Z"
                    fill="none"
                    stroke="#31006f"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                  />
                  <path
                    id="Path_959"
                    data-name="Path 959"
                    d="M3.27,6.96l7.76,4.489L18.79,6.96"
                    transform="translate(-0.03 -0.5)"
                    fill="none"
                    stroke="#31006f"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                  />
                  <line
                    id="Line_386"
                    data-name="Line 386"
                    y1="8.96"
                    transform="translate(11 11.042)"
                    fill="none"
                    stroke="#31006f"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                  />
                </g>
              </svg>
            </i>
            <div>
              <h6>شات مهمة رقم :</h6>
              <h5
                @click="
                  $router
                    .push(`/MissionDetails/` + info_chat_mission.code)
                    .catch(() => {})
                "
              >
                {{ info_chat_mission.code }}
              </h5>
            </div>
          </div>
          <div class="data-chat flex items-center">
            <div class="inner-data-chat">
              <h6>اسم الكابتن :</h6>
              <h5>{{ info_chat_mission.captain }}</h5>
            </div>
            <div class="inner-data-chat">
              <h6>الفرع المسئول :</h6>
              <h5>{{ info_chat_mission.branch }}</h5>
            </div>
          </div>
        </div>
      </div>
    </vs-navbar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import getColorStatus from "@/mixins/helper.js";
import ticketsMixins from "@/mixins/tickets.js";

export default {
  mixins: [getColorStatus, ticketsMixins],
  data() {
    return {
      status: "",
      assignedUser: "",
      SingleTicket: "",
      ticketType: "",
    };
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
    isPinnedProp: {
      type: Boolean,
      required: true,
    },
    isSidebarCollapsed: {
      type: Boolean,
      required: true,
    },
    typeOfChat: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters("chat", ["info_chat", "info_chat_mission"]),
    ...mapGetters("ticket", ["single_ticket"]),
    isPinnedLocal: {
      get() {
        return this.isPinnedProp;
      },
    },
    ticketStatus() {
      if (this.status == 0) {
        return "جديدة";
      } else if (this.status == 1) {
        return "جاري الحل";
      } else {
        return "تم الاغلاق";
      }
    },
  },
  methods: {
    ...mapActions("ticket", ["singleTicket"]),
  },
  mounted() {
    this.$socket.emit("join", {
      name: `ticket-status-${this.single_ticket.id}-changed`,
    });
    this.$socket.on(
      `ticket-status-${this.single_ticket.id}-changed`,
      (data) => {
        let type = parseInt(data.body.type);
        this.status = parseInt(data.body.status);
        this.assignedUser = data.body.assigned_user
          ? data.body.assigned_user.full_name
          : "";
        if (
          type != this.ticketType &&
          (type == this.allTypes.complaint || type == this.allTypes.inquiry)
        ) {
          this.$emit("singleTicket", this.single_ticket.id);
        }
        if (type != this.allTypes.gratitude && type != this.allTypes.suggestion)
          this.ticketType = type;
      }
    );
  },
  unmounted() {
    this.$socket.emit("leave", {
      name: `ticket-status-${this.single_ticket.id}-changed`,
    });
    this.$socket.off(`ticket-status-${this.single_ticket.id}-changed`);
  },
  created() {
    if (this.typeOfChat == 1) {
      this.status = this.single_ticket.status;
      this.assignedUser = this.single_ticket.assigned_user
        ? this.single_ticket.assigned_user.full_name
        : "";
      this.ticketType = this.single_ticket.type;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/vuexy/_variables.scss";
.number-chat {
  i {
    height: 40px;
    width: 40px;
    background-color: $mainColor2;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
  }
  h6,
  h5 {
    color: $mainColor1;
    font-size: 14px;
    line-height: 21px;
  }
  h5 {
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  }
}
.number-chat-ticket {
  h6,
  h5 {
    color: #6e6b7b;
  }
  h5 {
    font-weight: bold;
    text-decoration: unset;
    cursor: unset;
  }
}
.data-chat {
  .inner-data-chat {
    margin-left: 50px;
  }
  h6,
  h5 {
    font-size: 14px;
    line-height: 21px;
    color: #6e6b7b;
  }
  h5 {
    font-weight: bold;
  }
}
.vs-navbar {
  padding: 18px 24px !important;
}
</style>
