export default {
  methods: {
    formatDate(date) {
      return new Date(date).toLocaleTimeString("ar-EG", {
        hour: "2-digit",
        minute: "2-digit",
      });
    },
  },
};
